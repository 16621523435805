import React from 'react';
import '../News.css';

const NewsPage = () => {
    return (
        <div className="news-container">
            <h2>Here are the major data breaches that have been reported since May 1st, 2023:</h2>
            <ul>
                <li>
                    <strong>UPS Canada Data Breach</strong>
                    <p>On June 21, 2023, United Parcel Service hinted to its Canadian customers via a letter that their personal data may have been exposed in a breach. Fraudulent messages demanding payment before delivery were spotted, suggesting a possible data exposure. It was indicated that a person who searched for a particular package or misused a package lookup tool could have potentially uncovered personal information relating to customers, such as phone numbers</p>
                </li>
                <li>
                    <strong>MOVEit Hack Affecting the Province of Nova Scotia</strong>
                    <p>On June 1, 2023, MOVEit, a popular file transfer tool, was compromised, leading to sensitive data belonging to many firms that use the software being compromised as well. The province of Nova Scotia was among those reported to be affected. Russian ransomware group Clop claimed responsibility for the attack on June 6th</p>
                </li>
            </ul>
        </div>
    );
};

export default NewsPage;
