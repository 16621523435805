import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//import axios from 'axios';
//import firebase from 'firebase/compat/app';
import { collection, getDocs } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
//import AddBreach from './AddBreach';

// MainPage component
const MainPage = ({db}) => {
  const [data, setData] = useState([]);  // State to store the fetched data  
  const [sortField, setSortField] = useState(null); // Field to sort by
  const [sortDirection, setSortDirection] = useState('asc'); // Sorting direction

  // Use useEffect to fetch data once the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const db = getFirestore();
        const breachesRef = collection(db, 'breaches');
        const querySnapshot = await getDocs(breachesRef);
        const breachesData = querySnapshot.docs.map((doc) => ({
          id: doc.id, // Include the document ID
          ...doc.data(),
        }));
        setData(breachesData);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, [db]);

  // Sorting function
  const sortData = (field) => {
    let direction = sortDirection === 'asc' ? 'desc' : 'asc';
    if (field !== sortField) {
      direction = 'asc';
    }

    const sortedData = [...data].sort((a, b) => {
      if (a[field] < b[field]) return direction === 'asc' ? -1 : 1;
      if (a[field] > b[field]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setData(sortedData);
    setSortField(field);
    setSortDirection(direction);
  };

  //Pour formatter correctement les dates dans le tableau
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="container">
      <h1>Data Breach Information</h1>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" onClick={() => sortData('name')}>Entity impacted {sortField === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
            <th scope="col" onClick={() => sortData('date')}>Date {sortField === 'date' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
            <th scope="col" onClick={() => sortData('size')}>Victims {sortField === 'size' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
            <th scope="col">Details</th>
          </tr>
        </thead>
        <tbody>
          {/* Iterate over the fetched data and render table rows */}
          {data.map((breach, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>{breach.name}</td>
              <td>{formatDate(breach.date)}</td>
              <td>{breach.size}</td>
              <td><Link to={`/details/${breach.breach_id}`}>View details</Link></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MainPage;
