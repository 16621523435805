import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//import { collection, getDocs } from "firebase/firestore";
import { query, where, getDocs, collection, getFirestore } from "firebase/firestore";
//import axios from 'axios';

// DetailPage component
const DetailPage = () => {
  const { id } = useParams();  // Get the ID from the URL
  const breachId = Number(id); // Convert the ID to a number
  const [data, setData] = useState(null);  // State to store the fetched data

// Use useEffect to fetch data once the component mounts
useEffect(() => {
  const fetchData = async () => {
    try {
      const db = getFirestore();
      const detailsRef = collection(db, 'details'); // Reference to the 'details' collection
      const q = query(detailsRef, where('breach_id', '==', breachId)); // Query where breach_id matches the specified ID
      const querySnapshot = await getDocs(q); // Fetch the documents matching the query

      // Assuming there's only one matching document, you can extract its data like this:
      const detailData = querySnapshot.docs[0]?.data();

      setData(detailData); // Store the document's data in state
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  fetchData();
}, [breachId]);

  // Display a loading message if the data hasn't been fetched yet
  console.log(data)
  if (!data) {
    return <h1>Loading...</h1>;
  }

  return (
    <div className="container">
      {/* <h1>{data.breach_id}</h1> */}
      <div dangerouslySetInnerHTML={{ __html: data.summary }} />
    </div>
  );
};

export default DetailPage;
