import React from 'react';
import '../css/about.css';

const About = () => {
  return (
    <div className="about-container">
      <h1>Why have I created BreachStory ?</h1>
      <p>This website was created for the sole purpose of creating an easy to access and easy to browse list of data breaches that have happened in Canada over the years.</p>
      <p>Whenever I tried to find an exhaustive list of breaches, it would always take me to a website with tons of ads, or incomplete information, or both.</p>
      <p>So I took it upon myself to try and create a properly documented list of breaches, and I was surprised by how many I was able to find.</p>
      <p>Keep in mind that those are only the ones that have been publicly disclosed, which, if the litterature is correct, is dwarfed by the number of undisclosed breaches.</p>
      <p><b>Disclaimer</b>: This is by no means a perfect list, nor will it ever be perfectly up to date. If you notice that a particular breach that was made public is not documented here, feel free to reach out !</p>
    </div>
  );
};

export default About;