import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import firebaseConfig from './firebaseConfig'; // Update the path to your firebaseConfig.js file
import Navbar from './components/Navbar';
import MainPage from './components/MainPage';
import DetailPage from './components/DetailPage';
import NewsPage from './components/NewsPage';
import './App.css';
import About from './components/About';
import Contact from './components/Contact';

// App component
const App = () => {
  // Initialize Firebase
  
  const firebaseConfig = {
    apiKey : process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  };
  
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  //const testKey = process.env.REACT_APP_API_KEY;
  //const { REACT_APP_MY_ENV } = process.env;
  //console.log(process.env.REACT_APP_AUTH_DOMAIN)

  return (
    <Router>
      <Navbar />
      <div className="content">
        <Routes>
          <Route path="/" element={<MainPage db={db} />}></Route>
          <Route path="/news" element={<NewsPage />}></Route>
          <Route path="/details/:id" element={<DetailPage />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
        </Routes>
      </div>
    </Router>
  );
};

export default App;
